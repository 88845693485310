import './assets/css/main.css'
import 'hover.css/css/hover-min.css'
import 'animate.css';
import './assets/css/superwheel.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import QrcodeVue from 'qrcode.vue';
import 'vue3-easy-data-table/dist/style.css';
import socket from './common/socket'
import VueSplide from '@splidejs/vue-splide';
import { VueQrcodeReader } from "vue-qrcode-reader";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useAuthStore } from './stores/auth.store'
import '@splidejs/vue-splide/css';
import * as VueQuill from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'sweetalert2/src/sweetalert2.scss'

const app = createApp(App)

app.config.globalProperties.$cdnUrl = import.meta.env.VITE_CDN_URL

library.add(fas, far, fab)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(VueSplide);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('vue3-easy-data-table', Vue3EasyDataTable)
app.component('qrcode-vue', QrcodeVue)
app.component('quill-editor', VueQuill.QuillEditor);
app.use(VueQrcodeReader);

app.mount('#app')

const { logout } = useAuthStore()

if (!localStorage.getItem('client_ref')) {
  localStorage.setItem('client_ref', crypto.randomUUID())
}

const clientRef = localStorage.getItem('client_ref')
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['x-trace-id'] = crypto.randomUUID()
    config.headers['accept'] = 'application/json'
    config.headers['x-client-ref'] = clientRef
    return config
  },
  error => {
    toast(error?.message ?? 'An error occurred', {
      "position": "bottom-center",
      "theme": "auto",
      "type": 'error',
      "pauseOnFocusLoss": false,
      "closeOnClick": false,
      "hideProgressBar": true
    })
    return Promise.reject(new Error(error?.message ?? 'An error occurred'))
  }
)

axios.interceptors.response.use(
  response => {
    if (response.data.status) {
      toast(response.data.message, {
        "position": "bottom-center",
        "theme": "auto",
        "type": response.data.status,
        "pauseOnFocusLoss": false,
        "closeOnClick": false,
        "hideProgressBar": true
      })
    }
    return response
  },
  error => {
    const message = error?.response?.data?.message ?? 'An error occurred'
    const traceId = error?.response?.config?.headers?.["x-trace-id"] ?? crypto.randomUUID()
    toast(`
      <div class="flex flex-col items-center">
        <i class="fas fa-exclamation-triangle text-red-500 text-2xl mb-4"></i>
        <div class="text-sm mb-1 text-center">${message}</div>
        <div class="text-sm mb-4 text-center">${error.response?.status ?? error.response?.statusText ?? 'Network Error'}</div>
        <div class="text-[10px] text-center">Request ID: ${traceId}</div>
      </div>
      `, {
      "position": "bottom-center",
      "theme": "auto",
      "pauseOnFocusLoss": false,
      "closeOnClick": false,
      "hideProgressBar": true,
      "dangerouslyHTMLString": true
    })
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('auth')
      router.push({ name: 'home' })
      logout()
    }
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        if (error.response?.data.message === "ຍອດເງິນບໍ່ພຽງພໍ") { router.push('/payment/lao-qr'); }
      }
    }
    return Promise.reject(new Error(error))
  }
)

socket.connect()