import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue')
    },
    {
      'path': '/account',
      'name': 'account',
      'component': () => import('../views/AccountView.vue')
    },
    {
      'path': '/inbox',
      'name': 'inbox',
      'component': () => import('../views/InboxView.vue')
    },
    {
      'path': '/recharge-history',
      'name': 'recharge-history',
      'component': () => import('../views/RechargeHistoryView.vue')
    },
    {
      'path': '/order-history',
      'name': 'order-history',
      'component': () => import('../views/OrderHistoryView.vue')
    },
    {
      'path': '/developer-portal',
      'name': 'developer-portal',
      'component': () => import('../views/DeveloperPortalView.vue')
    },
    {
      'path': '/setting',
      'name': 'setting',
      'component': () => import('../views/SettingView.vue')
    },
    {
      'path': '/prepaid-card',
      'name': 'prepaid-card',
      'component': () => import('../views/PrepaidView.vue')
    },
    {
      'path': '/mobile-topup',
      'name': 'mobile-topup',
      'component': () => import('../views/MobileTopupView.vue')
    },
    {
      'path': '/product-detail/:code/:id',
      'name': 'product-detail',
      'component': () => import('../views/ProductDetail.vue')
    },
    {
      'path': '/product/:code',
      'name': 'product-list',
      'component': () => import('../views/ProductListView.vue')
    },
    {
      'path': '/product',
      'name': 'product',
      'component': () => import('../views/ProductView.vue')
    },
    {
      'path': '/game/:code',
      'name': 'game-topup',
      'component': () => import('../views/topup-views/GameTopup.vue')
    },
    {
      'path': '/prepaid-card/:code',
      'name': 'prepaid-card-topup',
      'component': () => import('../views/topup-views/CashcardView.vue')
    },
    {
      'path': '/mobile-topup/topup',
      'name': 'mobile-topup-topup',
      'component': () => import('../views/topup-views/MobileTopup.vue')
    },
    {
      'path': '/mobile-topup/internet',
      'name': 'mobile-topup-internet',
      'component': () => import('../views/topup-views/MobileInternetTopup.vue')
    },
    {
      'path': '/payment',
      'name': 'payment',
      'component': () => import('../views/PaymentView.vue')
    },
    {
      'path': '/payment/redeem-code',
      'name': 'payment-redeem-code',
      'component': () => import('../views/payment-channels/RedeemView.vue')
    },
    {
      'path': '/payment/prompt-pay',
      'name': 'payment-prompt-pay',
      'component': () => import('../views/payment-channels/PromptPayView.vue')
    },
    {
      'path': '/payment/truemoney-wallet-voucher',
      'name': 'payment-truemoney-wallet-voucher',
      'component': () => import('../views/payment-channels/VoucherView.vue')
    },
    {
      'path': '/payment/truemoney-wallet',
      'name': 'payment-truemoney-wallet',
      'component': () => import('../views/payment-channels/TruemoneyWalletView.vue')
    },
    {
      'path': '/payment/lao-qr',
      'name': 'payment-lao-qr',
      'component': () => import('../views/payment-channels/LaoQRView.vue')
    },
    {
      'path': '/payment/unitel',
      'name': 'payment-unitel',
      'component': () => import('../views/payment-channels/UnitelView.vue')
    },
    {
      'path': '/payment/unipay/thank-you',
      'name': 'payment-unipay-thank-you',
      'component': () => import('../views/UnipayThankView.vue')
    },
    {
      'path': '/payment/unipay',
      'name': 'payment-unipay',
      'component': () => import('../views/payment-channels/UnipayView.vue')
    },
    {
      'path': '/payment/lao-telecom',
      'name': 'payment-lao-telecom',
      'component': () => import('../views/payment-channels/LaoTelecomView.vue')
    },
    {
      'path': '/auth/line/callback',
      'name': 'auth-line-callback',
      'component': () => import('../callback/LineCallback.vue')
    },
    {
      'path': '/auth/google/callback',
      'name': 'auth-google-callback',
      'component': () => import('../callback/GoogleCallback.vue')
    },
    {
      'path': '/lucky-wheel',
      'name': 'wheel',
      'component': () => import('../views/WheelView.vue')
    },
    {
      'path': '/lucky-wheel/:id',
      'name': 'play-wheel',
      'component': () => import('../views/PlayWheelView.vue')
    },
    {
      'path': '/lucky-wheel-history',
      'name': 'wheel-history',
      'component': () => import('../views/WheelHistoryView.vue')
    }
  ]
})

export default router
